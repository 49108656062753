<template>
  <div class="couponList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>新闻列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>标题:</span>
      <el-input
        v-model.trim="list.title"
        placeholder="请输入新闻标题"
      ></el-input>
      <el-button type="primary" @click="tableList()">查找</el-button>
      <el-button type="primary" @click="$router.push('/newsAdd')"
        >添加</el-button
      >
      <!-- <el-button type="primary" @click="$router.push(`/addCoupon/0`)"
      v-if=" $store.state.powerList.indexOf('coupon:info:list:add') !== -1"
        >添加</el-button
      > -->
    </div>
    <el-table
      @selection-change="onSelectChange"
      :data="formData"
      border
      style="width: 100%"
    >
      <el-table-column
        align="center"
        type="selection"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="newsId"
        label="id"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cateName"
        label="所属分类"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="title"
        label="标题"
      ></el-table-column>
      <el-table-column align="center" prop="imgUrl" label="图片">
        <template slot-scope="{ row }">
          <el-image
            style="width: 80px; height: 80px"
            :src="row.imgUrl"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="添加时间"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="goEdit(row.newsId)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onChangeStatus(row.newsId, 2)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { newsList, newsDel } from "../../api/news";
export default {
  name: "couponList",
  data() {
    return {
      formData: [],
      pagination: {},
      ids: "",
      list: {
        currentPage: 1,
        pageSize: 5,
        cateId: "",
        title: "",
      },
    };
  },
  created() {
    this.tableList();
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    goEdit(id) {
      console.log(id, "id");
      this.$router.push(`/newsAdd?id=${id}`);
    },
    async tableList() {
      const { data } = await newsList(this.list);
      this.formData = data.list;
      this.pagination = data.pagination;
    },
    onChangeStatus(id) {
      this.$confirm(`是否要删除`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await newsDel({
            ids: id,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onDelRow(id) {
      this.$confirm("是否要设置这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: id + "",
            status: 0,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.couponId);
      });
      this.ids = arr.join(",");
    },
    async onChangeAll(status) {
      this.$confirm("是否要批量设置这些数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids == "") {
            return this.$message({
              type: "error",
              message: "请选择要设置的数据",
            });
          }

          const { data } = await changeStatus({
            ids: this.ids,
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onClear(v) {
      if (v == 1) {
        this.list.receiveWay = null;
      } else if (v == 2) {
        this.list.status = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.tableList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.tableList();
    },
  },
};
</script>
<style lang="less" scoped>
.couponList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 300px;
    }
    span {
      margin: 0 20px 0 40px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .btn {
    margin-top: 50px;
    float: left;
  }
}
</style>